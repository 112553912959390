const adminSideMenu = [
  {
    id: "adminHome",
    label: "Admin Home",
    href: "/admin/home",
    permissions: [99]
  },
  {
    id: "adminProduct",
    label: "Admin Product",
    href: "/admin/product",
    permissions: [99]
  },
  {
    id: "adminLocation",
    label: "Admin Location",
    href: "/admin/location",
    permissions: [99]
  },
  {
    id: "adminClient",
    label: "Admin Client & Partners",
    href: "/admin/client",
    permissions: [99]
  },
  {
    id: "adminRelations",
    label: "Admin Investor Relations",
    href: "/admin/investor",
    permissions: [99]
  },
  {
    id: "adminCompanyInfo",
    label: "Admin Company Info",
    href: "/admin/companyInfo",
    permissions: [99]
  },
  {
    id: "adminGovernance",
    label: "Admin Governance",
    href: "/admin/governance",
    permissions: [99]
  },
  {
    id: "adminActivity",
    label: "Admin Activities",
    href: "/admin/activities",
    permissions: [99]
  },
  {
    id: "adminGallery",
    label: "Admin Gallery",
    href: "/admin/gallery",
    permissions: [99]
  },
  {
    id: "adminCareer",
    label: "Admin Career",
    href: "/admin/career",
    permissions: [99]
  },
  {
    id: "adminCustomerCare",
    label: "Admin Customer Care",
    href: "/admin/customer-care",
    permissions: [99]
  }
];

export default adminSideMenu;
