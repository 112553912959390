import {
  Box,
  Button,
  Stack,
  Typography
} from "@mui/material";
import { DataGrid } from "@mui/x-data-grid";
import get from "lodash/get";
import { useState } from "react";
import { Helmet } from "react-helmet-async";
import { useQuery } from "react-query";
import { Link as RouterLink } from "react-router-dom";
import ProtectedPermissionPage from "../../components/ProtectedPermissionPage";
import link from "../../services/link";
import queryDefault from "../../utils/queryDefault";
import PageName from "../components/PageName";
import getImageLink from "../../utils/getImageLink";

const CustomNoRowOverlay = () => (
  <Box sx={{ pt: 5 }}>
    <Typography variant="body1" textAlign="center" fontWeight="600">
      Sorry, data is not found
    </Typography>
  </Box>
);

export default function CompanyInfoAdmin() {
  const [optionCompanyInfo] = useState<any>({
    keyword: "companyInfo",
  });
  const listCompanyInfo: any = useQuery(
    ["companyInfo", optionCompanyInfo],
    ({ queryKey }) => {
      return link.getLinks({
        ...queryKey[1],
      });
    },
    {
      ...queryDefault,
    }
  );
  const [optionCompanyInfoMobile] = useState<any>({
    keyword: "companyInfoMobile",
  });
  const listCompanyInfoMobile: any = useQuery(
    ["companyInfoMobile", optionCompanyInfoMobile],
    ({ queryKey }) => {
      return link.getLinks({
        ...queryKey[1],
      });
    },
    {
      ...queryDefault,
    }
  );

  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        backgroundColor: "#FDF3DF",
        height: "100vh",
        paddingRight: "5vw"
      }}
    >
      <Helmet>
        <title>Admin Company Info</title>
      </Helmet>

      <PageName page="COMPANY INFO" width="360" />

      <ProtectedPermissionPage acceptPermissions={[7]}>
        <Box sx={{ mt: 15, backgroundColor: "#fbf3dc" }}>
          <DataGrid
            getRowHeight={() => 'auto'}
            autoHeight
            loading={listCompanyInfo.isLoading}
            getRowId={(record) => get(record, "id")}
            rows={get(listCompanyInfo, "data.data", [])}
            rowCount={get(listCompanyInfo, "data.data", []).length}
            components={{
              NoRowsOverlay: CustomNoRowOverlay,
            }}
            columns={[
              {
                field: "link",
                headerName: "Company Info Banner",
                flex: 1,
                filterable: false,
                minWidth: 200,

                renderCell: (params) => {
                  return (
                    <Stack>
                      <a href={params.value} target="_blank" rel="noreferrer">Source</a>
                      <br></br>
                      <Typography>Preview:</Typography>
                      <img src={getImageLink(params.value, 500)} style={{ width: "50%" }} alt="Banner" />
                    </Stack>
                  );
                },
              },
              {
                field: "url",
                headerName: "Link URL",
                flex: 1,
                filterable: false,
                minWidth: 200,
                renderCell: (params) => {
                  return (
                    <Stack>
                      <a href={params.value} target="_blank" rel="noreferrer">URL</a>
                    </Stack>
                  );
                },
              },
              {
                field: "actions",
                headerName: "Action",
                sortable: false,
                filterable: false,
                width: 200,
                renderCell: (params) => {
                  return (
                    <Stack direction="row" spacing={1}>
                      <Button
                        variant="outlined"
                        color="primary"
                        component={RouterLink}
                        to={`/admin/companyinfo/edit-companyinfo/${get(params, "row.id")}`}
                      >
                        Edit
                      </Button>
                    </Stack>
                  );
                },
              },
            ]}
            sx={{
              ".MuiDataGrid-columnHeaderTitle": {
                fontWeight: 700,
              },
            }}
          />
        </Box>

        <Box sx={{ mt: 5, backgroundColor: "#fbf3dc" }}>
          <DataGrid
            getRowHeight={() => 'auto'}
            autoHeight
            loading={listCompanyInfoMobile.isLoading}
            getRowId={(record) => get(record, "id")}
            rows={get(listCompanyInfoMobile, "data.data", [])}
            rowCount={get(listCompanyInfoMobile, "data.data", []).length}
            components={{
              NoRowsOverlay: CustomNoRowOverlay,
            }}
            columns={[
              {
                field: "link",
                headerName: "Company Info Mobile Banner",
                flex: 1,
                filterable: false,
                minWidth: 200,

                renderCell: (params) => {
                  return (
                    <Stack>
                      <a href={params.value} target="_blank" rel="noreferrer">Source</a>
                      <br></br>
                      <Typography>Preview:</Typography>
                      <img src={getImageLink(params.value, 500)} style={{ width: "50%" }} alt="Banner" />
                    </Stack>
                  );
                },
              },
              {
                field: "url",
                headerName: "Link URL",
                flex: 1,
                filterable: false,
                minWidth: 200,
                renderCell: (params) => {
                  return (
                    <Stack>
                      <a href={params.value} target="_blank" rel="noreferrer">URL</a>
                    </Stack>
                  );
                },
              },
              {
                field: "actions",
                headerName: "Action",
                sortable: false,
                filterable: false,
                width: 200,
                renderCell: (params) => {
                  return (
                    <Stack direction="row" spacing={1}>
                      <Button
                        variant="outlined"
                        color="primary"
                        component={RouterLink}
                        to={`/admin/companyinfo/edit-companyinfo-mobile/${get(params, "row.id")}`}
                      >
                        Edit
                      </Button>
                    </Stack>
                  );
                },
              },
            ]}
            sx={{
              ".MuiDataGrid-columnHeaderTitle": {
                fontWeight: 700,
              },
            }}
          />
        </Box>
      </ProtectedPermissionPage>
    </Box>
  );
}
