import {
  Box,
  FormControl,
  Grid,
  InputLabel,
  MenuItem,
  Select,
  SelectChangeEvent,
  Stack
} from "@mui/material";
import CookieJs from "js-cookie";
import { useEffect, useState } from "react";
import { Helmet } from "react-helmet-async";
import { useQuery } from "react-query";
import { useNavigate } from "react-router-dom";
import link from "../../services/link";
import getImageLink from "../../utils/getImageLink";
import queryDefault from "../../utils/queryDefault";
import CustomCarousel from "../components/CustomCarousel";
import PageName from "../components/PageName";

export default function Home() {
  const navigate = useNavigate();
  const handleClickVisitUs = () => {
    navigate(`/location`);
  };
  const handleClickProduct = () => {
    navigate(`/product`);
  };

  const [optionBanner] = useState<any>({
    keyword: "homeBanner",
  });
  const [optionPromotion] = useState<any>({
    keyword: "homePromotion",
  });
  const [optionProduct] = useState<any>({
    keyword: "homeProduct",
  });
  const [optionVisitUs] = useState<any>({
    keyword: "homeVisitUs",
  });
  const bannerImages: any = useQuery(
    ["banner", optionBanner],
    ({ queryKey }) => {
      return link.getLinks({
        ...queryKey[1],
      });
    },
    {
      ...queryDefault,
    }
  );
  const promotionImages: any = useQuery(
    ["promotion", optionPromotion],
    ({ queryKey }) => {
      return link.getLinks({
        ...queryKey[1],
      });
    },
    {
      ...queryDefault,
    }
  );
  const productImage: any = useQuery(
    ["product", optionProduct],
    ({ queryKey }) => {
      return link.getLinks({
        ...queryKey[1],
      });
    },
    {
      ...queryDefault,
    }
  );
  const visitUsImage: any = useQuery(
    ["visitUs", optionVisitUs],
    ({ queryKey }) => {
      return link.getLinks({
        ...queryKey[1],
      });
    },
    {
      ...queryDefault,
    }
  );

  const [lang, setLang] = useState("en");
  useEffect(() => {
    const cookieLang = CookieJs.get("lang");
    if (cookieLang !== undefined) {
      setLang(cookieLang);
    }
  }, []);
  const changeLanguage = (event: SelectChangeEvent) => {
    if (event.target.value !== lang) {
      CookieJs.set("lang", event.target.value);
      setLang(event.target.value);
      window.location.reload();
    }
  };

  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        justifyContent: "flex-start",
        alignItems: "center",
        backgroundColor: "#FDF3DF",
        height: "100vh",
        paddingRight: "5vw"
      }}
    >
      <Helmet>
        <title>{lang === "en" ? "Home" : "BERANDA"}</title>
      </Helmet>

      <PageName page={lang === "en" ? "Home" : "Beranda"} width={lang === "en" ? "160" : "210"} />

      <FormControl sx={{ position: "absolute", top: "20px", right: "70px", zIndex: 99, backgroundColor: "rgb(253, 243, 223)", borderRadius: "5px" }}>
        <InputLabel id="lang-label">{lang === "en" ? "Language" : "Bahasa"}</InputLabel>
        <Select labelId="lang-label" id="lang" value={lang} label={lang === "en" ? "Language" : "Bahasa"} onChange={changeLanguage}>
          <MenuItem value="en">English</MenuItem>
          <MenuItem value="id">Indonesia</MenuItem>
        </Select>
      </FormControl>

      <Box sx={{ mt: "15vh", backgroundColor: "#fbf3dc", width: "90%" }}>
        <Stack direction="row" spacing={1}>
          <Grid container>
            <Grid item xs={12} sm={12}>
              {!bannerImages.isLoading ? <CustomCarousel images={bannerImages.data?.data} /> : <></>}
            </Grid>
          </Grid>
        </Stack>
      </Box>

      <Box sx={{ mt: 3, backgroundColor: "#fbf3dc", width: "90%" }}>
        <Grid container spacing={10} sx={{
          justifyContent: "center",
          display: "flex",
          flexDirection: "row"
        }}
        >
          <Grid item xs={9} sm={4}>
            {!promotionImages.isLoading ? <CustomCarousel images={promotionImages.data?.data} /> : <></>}
          </Grid>
          <Grid item xs={9} sm={4}>
            {!productImage.isLoading ? (
              <div style={{
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
              }}>
                <a rel="noreferrer" onClick={handleClickProduct}>
                  <img src={getImageLink(productImage.data?.data[0].link, 1000)} style={{ width: "100%", height: "auto", cursor: "pointer" }} alt="Location" />
                </a>
              </div>
            ) : <></>}
          </Grid>
          <Grid item xs={9} sm={4} >
            {!visitUsImage.isLoading ? (
              <div style={{
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center'
              }}>
                <a rel="noreferrer" onClick={handleClickVisitUs}>
                  <img src={getImageLink(visitUsImage.data?.data[0].link, 1000)} style={{ width: "100%", height: "auto", cursor: "pointer" }} alt="Visit Us" />
                </a>
              </div>
            ) : <></>}
          </Grid>
        </Grid>
      </Box>
    </Box>
  );
}
