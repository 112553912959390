import {
  Box,
  Button,
  Grid,
  InputLabel,
  MenuItem,
  Select,
  Stack,
  Typography
} from "@mui/material";
import { DataGrid } from "@mui/x-data-grid";
import get from "lodash/get";
import { useState } from "react";
import { Helmet } from "react-helmet-async";
import { useMutation, useQuery } from "react-query";
import { Link as RouterLink, useParams } from "react-router-dom";
import ProtectedPermissionPage from "../../components/ProtectedPermissionPage";
import useAlert from "../../hooks/useAlert";
import useNotification from "../../hooks/useNotification";
import service from "../../services/activities";
import getImageLink from "../../utils/getImageLink";
import queryDefault from "../../utils/queryDefault";
import PageName from "../components/PageName";

const CustomNoRowOverlay = () => (
  <Box sx={{ pt: 5 }}>
    <Typography variant="body1" textAlign="center" fontWeight="600">
      Sorry, data is not found
    </Typography>
  </Box>
);

export default function ActivitiesAdminCategory() {
  const { category } = useParams();
  const now = new Date()
  const [month, setMonth] = useState(now.getMonth() + 1);
  const [year, setYear] = useState(now.getFullYear());
  const [option, setOption] = useState<any>({
    type: category,
    month: now.getMonth() + 1,
    year: now.getFullYear()
  })
  const listActvities: any = useQuery(
    ["banner", option],
    ({ queryKey }) => {
      return service.getActivities({
        ...queryKey[1],
      });
    },
    {
      ...queryDefault,
    }
  );

  const handleMonthChange = (event: any) => {
    setMonth(event.target.value);
    setOption((option: any) => ({ ...option, month: event.target.value }));
    listActvities.refetch();
  };

  const handleYearChange = (event: any) => {
    setYear(event.target.value);
    setOption((option: any) => ({ ...option, year: event.target.value }));
    listActvities.refetch();
  };

  const yearOptions: number[] = []
  for (var i = 0; i < 10; i++) {
    yearOptions.push(now.getFullYear() - i);
  }

  const alert = useAlert();
  const notification = useNotification();
  const deleteActivity = useMutation("delete-activity", service.deleteActivity, {
    onSuccess: () => {
      listActvities.refetch();
      notification.onOpen({
        message: "Activity was successfully deleted!",
        type: "success",
        position: "top",
      });
    },
    ...queryDefault,
  });
  const onDelete = (id: number) => {
    alert.onClose();
    deleteActivity.mutate(id);
  };
  const onClickDelete = (id: number) => {
    alert.onOpen({
      title: "Confirm Delete?",
      message: "Are you sure want to delete this Activity?",
      actions: [
        {
          color: "error",
          variant: "contained",
          children: "Delete",
          onClick: () => onDelete(id),
        },
        {
          color: "primary",
          variant: "outlined",
          children: "Cancel",
          onClick: () => alert.onClose(),
        },
      ],
    });
  };

  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        backgroundColor: "#FDF3DF",
        height: "100vh",
        paddingRight: "5vw"
      }}
    >
      <Helmet>
        <title>Activites Admin Category</title>
      </Helmet>

      <PageName page="ACTIVITIES" width="255" />

      <ProtectedPermissionPage acceptPermissions={[7]}>
        <Box sx={{ mt: 15, backgroundColor: "#fbf3dc" }}>
          <Grid container spacing={10} sx={{
            justifyContent: "start",
            display: "flex",
            flexDirection: "row"
          }}
          >
            <Grid item xs={6} sm={6} lg={6}>
              <InputLabel id="select-year">Year</InputLabel>
              <Select
                labelId="select-year-label"
                id="select-year"
                label="Year"
                value={year}
                onChange={handleYearChange}
              >
                {yearOptions.map((year) => {
                  return (<MenuItem value={year}>{year}</MenuItem>)
                })}
              </Select>
            </Grid>
            <Grid item xs={6} sm={6} lg={6}>
              <InputLabel id="select-month">Month</InputLabel>
              <Select
                labelId="select-month-label"
                id="select-month"
                label="Month"
                value={month}
                onChange={handleMonthChange}
              >
                <MenuItem value={1}>January</MenuItem>
                <MenuItem value={2}>February</MenuItem>
                <MenuItem value={3}>March</MenuItem>
                <MenuItem value={4}>April</MenuItem>
                <MenuItem value={5}>May</MenuItem>
                <MenuItem value={6}>June</MenuItem>
                <MenuItem value={7}>July</MenuItem>
                <MenuItem value={8}>August</MenuItem>
                <MenuItem value={9}>September</MenuItem>
                <MenuItem value={10}>October</MenuItem>
                <MenuItem value={11}>November</MenuItem>
                <MenuItem value={12}>December</MenuItem>
              </Select>
            </Grid>
          </Grid>
        </Box>
        <Box sx={{ mt: 3, backgroundColor: "#fbf3dc" }}>
          <DataGrid
            getRowHeight={() => 'auto'}
            autoHeight
            loading={listActvities.isLoading}
            getRowId={(record) => get(record, "id")}
            rows={get(listActvities, "data.data", [])}
            rowCount={get(listActvities, "data.data", []).length}
            components={{
              NoRowsOverlay: CustomNoRowOverlay,
            }}
            columns={[
              {
                field: "order",
                headerName: "Order",
                flex: 1,
                filterable: false,
                minWidth: 60,

                renderCell: (params) => {
                  return (
                    <Stack>
                      <p>{params.value}</p>
                    </Stack>
                  );
                },
              },
              {
                field: "link",
                headerName: "Image Source",
                flex: 1,
                filterable: false,
                minWidth: 200,

                renderCell: (params) => {
                  return (
                    <Stack>
                      <a href={params.value} target="_blank" rel="noreferrer">Source</a>
                      <br></br>
                      <Typography>Preview:</Typography>
                      <img src={getImageLink(params.value, 500)} style={{ width: "50%" }} alt="activity" />
                    </Stack>
                  );
                },
              },
              {
                field: "actions",
                headerName: "Action",
                sortable: false,
                filterable: false,
                width: 200,
                renderCell: (params) => {
                  return (
                    <Stack direction="row" spacing={1}>
                      <Button
                        variant="outlined"
                        color="primary"
                        component={RouterLink}
                        to={`/admin/activities/edit-activity/${get(params, "row.id")}`}
                      >
                        Edit
                      </Button>
                      <Button
                        variant="outlined"
                        color="error"
                        onClick={() => onClickDelete(params.row.id)}
                      >
                        Delete
                      </Button>
                    </Stack>
                  );
                },
              },
            ]}
            sx={{
              ".MuiDataGrid-columnHeaderTitle": {
                fontWeight: 700,
              },
            }}
          />
        </Box>
        <Box sx={{ mt: 2, backgroundColor: "#fbf3dc" }}>
          <Stack direction="row" spacing={1}>
            <Button
              variant="outlined"
              component={RouterLink}
              to="/admin/activities/add-activity"
            >
              Add Activity
            </Button>
          </Stack>
        </Box>
      </ProtectedPermissionPage>
    </Box>
  );
}
