import {
  Box,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  SelectChangeEvent
} from "@mui/material";
import CookieJs from "js-cookie";
import { useEffect, useState } from "react";
import { Helmet } from "react-helmet-async";
import { useQuery } from "react-query";
import link from "../../services/link";
import getImageLink from "../../utils/getImageLink";
import queryDefault from "../../utils/queryDefault";
import PageName from "../components/PageName";

export default function ClientPartner() {
  const [optionClient] = useState<any>({
    keyword: "clientPartner",
  });
  const listClient: any = useQuery(
    ["clientPartner", optionClient],
    ({ queryKey }) => {
      return link.getLinks({
        ...queryKey[1],
      });
    },
    {
      ...queryDefault,
    }
  );

  const [optionClientMobile] = useState<any>({
    keyword: "clientPartnerMobile",
  });
  const listClientMobile: any = useQuery(
    ["clientMobile", optionClientMobile],
    ({ queryKey }) => {
      return link.getLinks({
        ...queryKey[1],
      });
    },
    {
      ...queryDefault,
    }
  );

  const [lang, setLang] = useState("en");
  useEffect(() => {
    const cookieLang = CookieJs.get("lang");
    if (cookieLang !== undefined) {
      setLang(cookieLang);
    }
  }, []);
  const changeLanguage = (event: SelectChangeEvent) => {
    if (event.target.value !== lang) {
      CookieJs.set("lang", event.target.value);
      setLang(event.target.value);
      window.location.reload();
    }
  };

  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        alignItems: "center",
        backgroundColor: "#FDF3DF",
        height: "100vh",
        paddingRight: "5vw"
      }}
    >
      <Helmet>
        <title>{lang === "en" ? "Clients & Partners" : "Klien & Partner"}</title>
      </Helmet>

      <PageName page={lang === "en" ? "CLIENTS & PARTNERS" : "KLIEN & PARTNER"} width={lang === "en" ? "460" : "400"} />

      <FormControl sx={{ position: "absolute", top: "20px", right: "70px", zIndex: 99, backgroundColor: "rgb(253, 243, 223)", borderRadius: "5px" }}>
        <InputLabel id="lang-label">{lang === "en" ? "Language" : "Bahasa"}</InputLabel>
        <Select labelId="lang-label" id="lang" value={lang} label={lang === "en" ? "Language" : "Bahasa"} onChange={changeLanguage}>
          <MenuItem value="en">English</MenuItem>
          <MenuItem value="id">Indonesia</MenuItem>
        </Select>
      </FormControl>

      <Box sx={{ mt: 10, backgroundColor: "#fbf3dc", width: "100%", padding: "10px" }}>
        {!listClient.isLoading && !listClientMobile.isLoading ? (
          <picture>
            <source
              media="(min-width:900px)"
              srcSet={getImageLink(listClient.data?.data[0].link, 1440)}
            />
            <img src={getImageLink(listClientMobile.data?.data[0].link, 1440)} style={{ width: "100%", height: "100%", objectFit: "cover" }} alt="Clients" />
          </picture>
        ) : <></>}
      </Box>
    </Box>
  );
}
