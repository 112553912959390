import { Navigate, useRoutes } from "react-router-dom";
import { useAppSelector } from "../hooks/useSelector";
import AdminDashboard from "../layouts/AdminDashboard";
import DashboardLayout from "../layouts/Dashboard";
import AccountProfilePage from "../pages/AccountProfilePage";
import AdminCareer from "../pages/AdminCareer";
import Career from "../pages/Career";
import ChangePasswordPage from "../pages/ChangePasswordPage";
import PageNotFound from "../pages/PageNotFound";
import SignInPage from "../pages/SignInPage";
import Activites from "../pages/activities/Activities";
import ActivitesAdmin from "../pages/activities/ActivitiesAdmin";
import ActivitesAdminCategory from "../pages/activities/ActivitiesAdminCategory";
import ActivitiesAdminCreateEdit from "../pages/activities/ActivitiesAdminCreateEdit";
import ClientPartner from "../pages/clientpartner/ClientPartner";
import ClientPartnerAdmin from "../pages/clientpartner/ClientPartnerAdmin";
import ClientPartnerAdminEdit from "../pages/clientpartner/ClientPartnerAdminEdit";
import ClientPartnerMobileAdminEdit from "../pages/clientpartner/ClientPartnerMobileAdminEdit";
import CustomerCare from "../pages/customercare/CustomerCare";
import CustomerCareAdmin from "../pages/customercare/CustomerCareAdmin";
import CustomerCareAdminCreateEdit from "../pages/customercare/CustomerCareAdminCreateEdit";
import CustomerCareDetailAdmin from "../pages/customercare/CustomerCareDetailAdmin";
import CustomerCareLinkAdminCreate from "../pages/customercare/CustomerCareLinkAdminCreate";
import CustomerCareLinkAdminEdit from "../pages/customercare/CustomerCareLinkAdminEdit";
import CustomerCareSectionAdminCreate from "../pages/customercare/CustomerCareSectionAdminCreate";
import CustomerCareSectionAdminEdit from "../pages/customercare/CustomerCareSectionAdminEdit";
import CustomerCareSectionDetailAdmin from "../pages/customercare/CustomerCareSectionDetailAdmin";
import Gallery from "../pages/gallery/Gallery";
import GalleryAdmin from "../pages/gallery/GalleryAdmin";
import GalleryAdminCreateEdit from "../pages/gallery/GalleryAdminCreateEdit";
import Governance from "../pages/governance/Governance";
import GovernanceAdmin from "../pages/governance/GovernanceAdmin";
import GovernanceAdminEdit from "../pages/governance/GovernanceAdminEdit";
import GovernanceMobileAdminEdit from "../pages/governance/GovernanceMobileAdminEdit";
import Home from "../pages/home/Home";
import HomeAdmin from "../pages/home/HomeAdmin";
import HomeAdminCreateEditBanner from "../pages/home/HomeAdminCreateEditBanner";
import HomeAdminCreateEditPromotion from "../pages/home/HomeAdminCreateEditPromotion";
import HomeAdminEditProduct from "../pages/home/HomeAdminEditProduct";
import HomeAdminEditVisitUs from "../pages/home/HomeAdminEditVisitUs";
import CompanyInfo from "../pages/companyinfo/CompanyInfo";
import Investor from "../pages/investor/Investor";
import InvestorAdmin from "../pages/investor/InvestorAdmin";
import InvestorAdminCreateEdit from "../pages/investor/InvestorAdminCreateEdit";
import Location from "../pages/location/Location";
import LocationAdmin from "../pages/location/LocationAdmin";
import LocationAdminEdit from "../pages/location/LocationAdminEdit";
import LocationMobileAdminEdit from "../pages/location/LocationMobileAdminEdit";
import Product from "../pages/product/Product";
import ProductAdmin from "../pages/product/ProductAdmin";
import ProductAdminCreateEdit from "../pages/product/ProductAdminCreateEdit";
import ProductDetailAdmin from "../pages/product/ProductDetailAdmin";
import SubProductAdminCreate from "../pages/product/SubProductAdminCreate";
import SubProductAdminEdit from "../pages/product/SubProductAdminEdit";
import CompanyInfoAdmin from "../pages/companyinfo/CompanyInfoAdmin";
import CompanyInfoAdminEdit from "../pages/companyinfo/CompanyInfoAdminEdit";
import CompanyInfoMobileAdminEdit from "../pages/companyinfo/CompanyInfoMobileAdminEdit";

export default function Routes() {
  const { user } = useAppSelector((state) => state.global);
  const chooseDefaultRoute: any = () => {
    if (user.userId) {
      return { element: <Navigate to="/admin/home" />, index: true };
    }
    return { element: <Navigate to="/admin/login" />, index: true };
  };
  return useRoutes([
    {
      path: "/admin",
      element: <AdminDashboard />,
      children: [
        {
          path: "/admin",
          children: [chooseDefaultRoute()],
        },
        {
          path: "/admin/profile",
          children: [
            { element: <AccountProfilePage />, index: true },
            {
              element: <ChangePasswordPage />,
              path: "/admin/profile/change-password",
            },
          ],
        },
        {
          path: "/admin/home",
          children: [
            { element: <HomeAdmin />, index: true },
            {
              element: <HomeAdminCreateEditBanner />,
              path: "/admin/home/edit-banner/:id",
            },
            {
              element: <HomeAdminCreateEditBanner />,
              path: "/admin/home/add-banner",
            },
            {
              element: <HomeAdminCreateEditPromotion />,
              path: "/admin/home/edit-promotion/:id",
            },
            {
              element: <HomeAdminCreateEditPromotion />,
              path: "/admin/home/add-promotion",
            },
            {
              element: <HomeAdminEditProduct />,
              path: "/admin/home/edit-product/:id",
            },
            {
              element: <HomeAdminEditVisitUs />,
              path: "/admin/home/edit-visit-us/:id",
            },
          ],
        },
        {
          path: "/admin/career",
          children: [
            { element: <AdminCareer />, index: true },
          ],
        },
        {
          path: "/admin/location",
          children: [
            { element: <LocationAdmin />, index: true },
            {
              element: <LocationAdminEdit />,
              path: "/admin/location/edit-location/:id",
            },
            {
              element: <LocationMobileAdminEdit />,
              path: "/admin/location/edit-location-mobile/:id",
            },
          ],
        },
        {
          path: "/admin/investor",
          children: [
            { element: <InvestorAdmin />, index: true },
            {
              element: <InvestorAdminCreateEdit />,
              path: "/admin/investor/edit-investor/:id",
            },
            {
              element: <InvestorAdminCreateEdit />,
              path: "/admin/investor/add-investor",
            }
          ],
        },
        {
          path: "/admin/companyinfo",
          children: [
            { element: <CompanyInfoAdmin />, index: true },
            {
              element: <CompanyInfoAdminEdit />,
              path: "/admin/companyinfo/edit-companyinfo/:id",
            },
            {
              element: <CompanyInfoMobileAdminEdit />,
              path: "/admin/companyinfo/edit-companyinfo-mobile/:id",
            },
          ],
        },
        {
          path: "/admin/governance",
          children: [
            { element: <GovernanceAdmin />, index: true },
            {
              element: <GovernanceAdminEdit />,
              path: "/admin/governance/edit-governance/:id",
            },
            {
              element: <GovernanceMobileAdminEdit />,
              path: "/admin/governance/edit-governance-mobile/:id",
            },
          ],
        },
        {
          path: "/admin/gallery",
          children: [
            { element: <GalleryAdmin />, index: true },
            {
              element: <GalleryAdminCreateEdit />,
              path: "/admin/gallery/edit-gallery/:id",
            },
            {
              element: <GalleryAdminCreateEdit />,
              path: "/admin/gallery/add-gallery",
            }
          ],
        },
        {
          path: "/admin/client",
          children: [
            { element: <ClientPartnerAdmin />, index: true },
            {
              element: <ClientPartnerAdminEdit />,
              path: "/admin/client/edit-client/:id",
            },
            {
              element: <ClientPartnerMobileAdminEdit />,
              path: "/admin/client/edit-client-mobile/:id",
            },
          ],
        },
        {
          path: "/admin/product",
          children: [
            { element: <ProductAdmin />, index: true },
            {
              element: <ProductAdminCreateEdit />,
              path: "/admin/product/edit-product/:id",
            },
            {
              element: <ProductAdminCreateEdit />,
              path: "/admin/product/add-product",
            },
            {
              element: <ProductDetailAdmin />,
              path: "/admin/product/:id",
            },
            {
              element: <SubProductAdminEdit />,
              path: "/admin/product/edit-sub-product/:id",
            },
            {
              element: <SubProductAdminCreate />,
              path: "/admin/product/add-sub-product/:productid",
            },
          ],
        },
        {
          path: "/admin/customer-care",
          children: [
            { element: <CustomerCareAdmin />, index: true },
            {
              element: <CustomerCareAdminCreateEdit />,
              path: "/admin/customer-care/edit/:id",
            },
            {
              element: <CustomerCareAdminCreateEdit />,
              path: "/admin/customer-care/add",
            },
            {
              element: <CustomerCareDetailAdmin />,
              path: "/admin/customer-care/:id",
            },
            {
              element: <CustomerCareSectionAdminEdit />,
              path: "/admin/customer-care/edit-section/:id",
            },
            {
              element: <CustomerCareSectionAdminCreate />,
              path: "/admin/customer-care/add-section/:customercareid",
            },
            {
              element: <CustomerCareSectionDetailAdmin />,
              path: "/admin/customer-care/section/:id",
            },
            {
              element: <CustomerCareLinkAdminEdit />,
              path: "/admin/customer-care/edit-link/:id",
            },
            {
              element: <CustomerCareLinkAdminCreate />,
              path: "/admin/customer-care/add-link/:customercaresectionid",
            },
          ],
        },
        {
          path: "/admin/activities",
          children: [
            { element: <ActivitesAdmin />, index: true },
            {
              element: <ActivitesAdminCategory />,
              path: "/admin/activities/:category",
            },
            {
              element: <ActivitiesAdminCreateEdit />,
              path: "/admin/activities/edit-activity/:id",
            },
            {
              element: <ActivitiesAdminCreateEdit />,
              path: "/admin/activities/add-activity",
            },
          ],
        },
        {
          path: "/admin/users",
          element: <Navigate to="/admin/users/manage" />,
        },
        {
          path: "*",
          element: <PageNotFound fullWidth={false} />,
        },
      ],
    },
    {
      path: "/admin/login",
      element: <SignInPage />,
      index: true,
    },
    {
      path: "/",
      element: <DashboardLayout />,
      children: [
        {
          path: "/",
          element: <Navigate to="/home" />,
        },
        {
          path: "/home",
          element: <Home />,
        },
        {
          path: "/location",
          element: <Location />,
        },
        {
          path: "/relations",
          element: <Investor />,
        },
        {
          path: "/company-info",
          element: <CompanyInfo />,
        },
        {
          path: "/governance",
          element: <Governance />,
        },
        {
          path: "/client",
          element: <ClientPartner />,
        },
        {
          path: "/customer-care",
          element: <CustomerCare />
        },
        {
          path: "/product",
          element: <Product />
        },
        {
          path: "/gallery",
          element: <Gallery />
        },
        {
          path: "/career",
          element: <Career />
        },
        {
          path: "/activities",
          element: <Activites />
        },
        {
          path: "*",
          element: <PageNotFound fullWidth />,
        },
      ],
    },
  ]);
}
